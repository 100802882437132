import React from 'react';
import PropTypes from 'prop-types';

const ImageList = ({ images }) => (
    <div>
        <ul className="list">
            {images.map(image => (
                <li className="list-item" key={image.url}>
                    <img src={image.url} alt={image.alt} width={image.width} height={image.height} />
                </li>
            ))}
        </ul>
    </div>
);

ImageList.propTypes = {
    images: PropTypes.array
};

export default ImageList;