import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from "react-router-dom";
import { isEqual } from 'lodash';

import Card from '../../../../components/Card';
import Button from '../../../../components/Button';
import ImageList from '../../../../components/ImageList';

import { deleteBucketDocument, getBucketDocumentContent } from '../../../../services/application-service';

const SwrFetchData = ({ t, documentName }) => {
    const { id } = useParams();

    const history = useHistory();

    const [content, setContent] = useState(null);

    const [imageList, setImageList] = useState([]);

    function deleteDocument(docKey) {
        deleteBucketDocument(docKey);
        history.push(`/`);
        window.location.reload();
    }

    useEffect(() => {
        const documentPromise = getBucketDocumentContent(documentName);
        documentPromise.then(docs => {
            if (isEqual(Object.entries(docs)[0], ['isTextOrImages', true])) {
                if (docs.content) {
                    setContent(docs.content);
                }

                if (docs.images) {
                    setImageList(docs.images);
                }
            } else {
                setContent(JSON.stringify(docs, null, 4));
            }
        });
    }, []);

    return (
        <div>
            <div className="ph1 ph4-m ph5-ns pb5">
                {!!document && (
                    <div>
                        <h3>{documentName}</h3>
                        {
                            content &&
                            <Card className="content-card">
                                <div>
                                    <pre>
                                        <span className="f5 mb2">
                                            <p className='text-content'>{content}</p>
                                        </span>
                                    </pre>
                                </div>
                            </Card>
                        }
                        {
                            imageList.length > 0 &&
                            <ImageList images={imageList} />
                        }
                    </div>
                )}
                <div className='buttons-bar'>
                    <div className='save-button'>
                        {
                            !documentName.startsWith('blip_portal:') &&
                            <Button
                                variant="primary"
                                icon="edit"
                                text={t('button.editDocument')}
                                onClick={() => history.push(`/document/edit/${id}`)}
                            />
                        }
                    </div>
                    <div className='cancel-button'>
                        {
                            !documentName.startsWith('blip_portal:') &&
                            <Button
                                variant="delete"
                                icon="trash"
                                text={t('button.deleteDocument')}
                                onClick={() => deleteDocument(id)}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

SwrFetchData.propTypes = {
    t: PropTypes.func,
    documentName: PropTypes.string
};

export default SwrFetchData;
