const getSavedPatterns = () => {
    try {
        const savedPatterns = localStorage.getItem('dawntech_bucket_manager_patterns');
        return JSON.parse(savedPatterns);
    } catch {
        return null;
    }
};

const patterns = getSavedPatterns() || ["blip_portal:.*"];

const updatePatterns = () => {
    localStorage.setItem('dawntech_bucket_manager_patterns', JSON.stringify(patterns));
};

const addPattern = (pattern) => {
    patterns.push(pattern);
    updatePatterns();
};

const removePattern = (index) => {
    if (index < 0 || index >= patterns.length) {
        throw new Error(`No pattern found for index ${index}`);
    }
    const removed = patterns.splice(index, 1);
    updatePatterns();
    return removed[0];
};

const logPatterns = () => {
    console.table(patterns);
};

const getBlackListPatterns = () => patterns.map(pattern => new RegExp(pattern, 'i'));

// eslint-disable-next-line
global.dawntech_bucket_manager_sdk = {
    addPattern,
    removePattern,
    logPatterns
};

export { getBlackListPatterns };