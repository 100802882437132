import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import RoutesPath from '../constants/routes-path';

import HomePage from '../pages/Home';
import DocumentPage from '../pages/Document';
import DocumentEditor from '../pages/DocumentEditor';

import Analytics from './Analytics';

const Routes = () => (
    <BrowserRouter>
        <Analytics>
            <Switch>
                <Route exact path={RoutesPath.HOME.PATH} component={HomePage} />
                <Route exact path={RoutesPath.DOCUMENT_PAGE.PATH} component={DocumentPage} />
                <Route exact path={RoutesPath.DOCUMENT_EDITOR.PATH} component={DocumentEditor} />
            </Switch>
        </Analytics>
    </BrowserRouter>
);

export default Routes;
