const translations = {
    title: {
        homePage: 'Bucket Manager',
        exemples: 'Exemplos',
        systemInfo: 'Informações do sistema',
        userInfo: 'Informações do usuário'
    },
    paragraph: {
        homeDescription: {
            part1: 'Este é um modelo de plugin básico',
            part2: 'Edite <1>src/pages/Home.js</1> e salve para recarregar.'
        }
    },
    link: {
        blipDataExample: 'Obtenha dados do blip',
        swrExemple: 'Buscar dados com SWR'
    },
    button: {
        repository: 'Acesse a Documentação',
        createNewDocument: 'Criar novo documento',
        saveDocument: 'Salvar documento',
        cancel: 'Cancelar',
        save: 'Salvar',
        editDocument: 'Editar documento',
        deleteDocument: 'Deletar documento'
    },
    input: {
        saveAs: 'Salvar como'
    },
    messages: {
        invalidDoc: 'Documento inválido: ',
        emptyDoc: 'O documento está vazio',
        sameName: 'Se já existir um documento com o mesmo nome, seu conteúdo será sobescrito',
        cantChangeDoc: `Você não pode alterar esse documento por esse plugin`,
        editing: 'Editando ',
        blipPortalPrefix: `O prefixo 'blip_portal:' é reservado para documentos internos do bucket`
    },
    menu: {
        goBack: 'Voltar'
    },
    name: 'Nome',
    shortName: 'Nome abreviado',
    email: 'E-mail',
    phone: 'Telefone',
    identity: 'Identificador',
    identifier: 'Identificador',
    language: 'pt',
    accessKey: 'Chave de acesso',
    template: 'Template',
    creationDate: 'Data de criação',
    documentEditor: 'Editor de Documento'
};

export default translations;
