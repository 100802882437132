import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AtomicBlockUtils, ContentState, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { BdsInput, BdsModal, BdsModalAction } from 'blip-ds/dist/blip-ds-react';

import { getBucketDocumentContent, storeBucketJSON } from '../../services/application-service';

import Card from '../../components/Card';
import Button from '../../components/Button';
import ContentPageHeader from '../../components/ContentPageHeader';

const DocumentEditor = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const history = useHistory();

    const [invalidDocument, setInvalidDocument] = useState(null);

    const [content, setContent] = useState(null);

    const [openModal, setOpenModal] = useState(false);

    const [documentName, setDocumentName] = useState(id === "new" ? "" : id);

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty()
    );

    useEffect(() => {
        if (id !== "new") {
            getInitialState(id).then(state => setEditorState(state));
        }
    }, []);

    const getInitialState = async () => {
        const response = await getBucketDocumentContent(id);

        let initialContent = ContentState.createFromText("");

        if (!isEqual(Object.entries(response)[0], ['isTextOrImages', true])) {
            initialContent = ContentState.createFromText(JSON.stringify(response, null, 4));
        } else if (response.content) {
            initialContent = ContentState.createFromText(`${response.content}`);
        }

        let initialEditorState = EditorState.createWithContent(initialContent);

        if (isEqual(Object.entries(response)[0], ['isTextOrImages', true]) && response.images.length > 0) {
            response.images.forEach(image => {
                const initialContentState = initialEditorState.getCurrentContent();

                const contentStateWithEntity = initialContentState.createEntity('IMAGE', 'MUTABLE', { src: image.url });

                const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

                initialEditorState = AtomicBlockUtils.insertAtomicBlock(EditorState.createWithContent(contentStateWithEntity), entityKey, ' ');
            });
        }


        return initialEditorState;
    };

    const parseContent = useCallback((editorContent) => {
        const { blocks, entityMap } = editorContent;

        const textContent = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

        let entityNumber = 0;

        blocks.map(block => {
            if (block.entityRanges.length) {
                if (block.type === 'unstyled') {
                    delete entityMap[entityNumber];
                }
                entityNumber++;
            }
            return null;
        }
        );

        const entityMapValues = Object.values(entityMap);

        const images = [];

        entityMapValues.forEach(entity => {
            if (entity.type === 'IMAGE') {
                images.push({
                    url: entity.data.src,
                    height: entity.data.height,
                    width: entity.data.width,
                    alt: entity.data.alt
                });
            }
        });

        try {
            const parsedContent = JSON.parse(textContent);
            setContent(parsedContent);
            setInvalidDocument(null);
        } catch (e) {
            if (textContent.replace(/(\r\n|\n|\r)/gm, "")) {
                setInvalidDocument(null);

                setContent({
                    isTextOrImages: true,
                    content: textContent,
                    images
                });
            } else if (entityMapValues.length) {
                setContent({
                    isTextOrImages: true,
                    images
                });
                setInvalidDocument(null);
            } else {
                setInvalidDocument(t('messages.emptyDoc'));
            }
        }
    }, []);

    const saveDocument = useCallback(() => {
        try {
            storeBucketJSON(documentName, content);

            if (id === "new") {
                history.push(`/`);
            }
            window.location.reload();

        } catch (e) {
            console.error(e.message);
        }

    }, [editorState, documentName, content]);

    return (
        <div className="document-editor">
            {
                id.startsWith('blip_portal:') &&
                <p>{t('messages.cantChangeDoc')}</p>
            }
            {
                !id.startsWith('blip_portal:') &&
                <div>
                    <ContentPageHeader
                        title={t('menu.goBack')}
                        canRefresh
                    />
                    <div>
                        <h3>{t('documentEditor')}</h3>
                        {
                            id !== "new" &&
                            <h4>{`${t('messages.editing')} ${id}`}</h4>
                        }
                        <Card className="document-editor-card">
                            <Editor
                                editorState={editorState}
                                onEditorStateChange={setEditorState}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                toolbar={{
                                    options: ['history', 'image'],
                                    image: { alt: { present: true } }
                                }}
                                stripPastedStyles
                            />
                            <Button variant="primary" text={t('button.saveDocument')} onClick={() => { parseContent(JSON.parse(JSON.stringify(convertToRaw(editorState.getCurrentContent())))); setOpenModal(true); }} />
                        </Card>
                    </div>
                    <div className='modalDocumentName'>
                        <BdsModal open={openModal} closeButton={false} className='modalDocumentName' style={{ height: "100px" }}>
                            <div className='modalDocumentName'>
                                <BdsInput value={documentName} label={t('input.saveAs')} onBdsChange={(evt) => setDocumentName(evt.detail.value)} className='inputStyle' />
                                {
                                    invalidDocument &&
                                    <p className='invalid-json'>{t('messages.invalidDoc')} {invalidDocument}</p>
                                }
                                <div>
                                    <p>{t('messages.sameName')}</p>
                                    {
                                        documentName.startsWith('blip_portal:') &&
                                        <p style={{ color: "red" }}>{t('messages.blipPortalPrefix')}</p>
                                    }
                                    <BdsModalAction>
                                        <div className="cancel-button">
                                            <Button variant="delete" onClick={() => setOpenModal(false)}>
                                                {t('button.cancel')}
                                            </ Button>
                                        </div>
                                        {
                                            !documentName.startsWith('blip_portal:') &&
                                            <Button disabled={documentName.trim().length === 0 || invalidDocument !== null} onClick={() => saveDocument()}>{t('button.save')}</Button>
                                        }
                                    </BdsModalAction>
                                </div>
                            </div>
                        </BdsModal>
                    </div>
                </div>
            }
        </div>
    );
};

export default DocumentEditor;