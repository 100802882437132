import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import PageHeader from '../PageHeader';

const ContentPageHeader = ({ title, canRefresh = false, onRefresh }) => {
    const history = useHistory();

    const handleNavigation = () => history.goBack();

    const renderOptions = () => (
        <button
            data-testid="refresh-button"
            type="button"
            className="f4 fw6 db black link dim b--none bg-transparent pointer"
            onClick={onRefresh}
        >
            <bds-icon name="refresh" />
        </button>
    );

    return (
        <PageHeader
            title={title}
            isBackNavigation
            relatedOptions={canRefresh && renderOptions()}
            onBackPressed={() => handleNavigation()}
        />
    );
};

ContentPageHeader.propTypes = {
    title: PropTypes.string,
    canRefresh: PropTypes.bool,
    onRefresh: PropTypes.func
};

export default ContentPageHeader;