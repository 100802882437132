import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import RoutesPath from '../../constants/routes-path';

// import settings from '../../config';

import Header from './components/Header';
import { deleteBucketDocument, getBucketDocuments } from '../../services/application-service';
import { getBlackListPatterns } from '../../services/blacklist-service';
import Card from '../../components/Card';
import Button from '../../components/Button';

const PAGE_ICON = 'plugin';
const BLANK = '_blank';

const documentsPromise = getBucketDocuments();

const Home = () => {
    const [documentNames, setDocumentNames] = useState([]);
    const { t } = useTranslation();
    const history = useHistory();

    function deleteDocument(docKey) {
        deleteBucketDocument(docKey);
        window.location.reload();
    }

    const handleNavigation = useCallback((documentKey) => {
        if (documentKey) {
            history.push(`/document/edit/${documentKey}`);
        } else {
            history.push('/document/edit/new');
        }
    }, [history]);

    useEffect(() => {
        const blacklistPatterns = getBlackListPatterns();
        documentsPromise.then(documentResponse => {
            if (!documentResponse || !Array.isArray(documentResponse.items)) {
                return;
            } 
            const filteredDocs = documentResponse.items.filter(doc => !blacklistPatterns.some(pattern => pattern.test(doc)));
            setDocumentNames(filteredDocs);
        });
    }, []);

    return (
        <div className="ph1 ph4-m ph5-ns pb5">
            <Header
                title={t('title.homePage')}
                icon={PAGE_ICON}
                onClick={() => window.open(`https://docs.dawntech.dev/${t('language')}/blip-bucket-viewer`, BLANK)}
            />
            <div className="flex flex-column justify-center bp-c-neutral-dark-city f5 h-100 mt4">
                <div>
                    <ul className="list">
                        {documentNames.map(item => 
                            <li className="list-item" key={item}>
                                <Card>
                                    <Link to={`/document/${item}`} className="document-name">{item}</Link>
                                    <button
                                        data-testid="delete-button"
                                        type="button"
                                        className="trash-icon db black link dim b--none bg-transparent pointer"
                                        onClick={() => deleteDocument(item)}
                                    >
                                        <bds-icon name="trash" />
                                    </button>
                                    <button
                                        data-testid="edit-button"
                                        type="button"
                                        className="trash-icon db black link dim b--none bg-transparent pointer"
                                        onClick={() => handleNavigation(item)}
                                    >
                                        <bds-icon name="edit" />
                                    </button>
                                </Card>
                            </li>
                        )}
                    </ul>
                </div>
                <div className="newDocButton">
                    <Button variant="primary" text={t('button.createNewDocument')} onClick={() => handleNavigation(null)} />
                </div>
            </div>
        </div>
    );
};

Home.propTypes = {};

export default Home;
