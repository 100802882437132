import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SwrFetchData from './components/SwrFetchData';
import ContentPageHeader from '../../components/ContentPageHeader';

const Document = () => {
    const { t } = useTranslation();

    const { id } = useParams();

    return (
        <div className="documentView">
            <ContentPageHeader
                title={t('menu.goBack')}
                canRefresh
            />
            <SwrFetchData t={t} documentName={id} />
        </div>
    );
};

export default Document;
