const translations = {
    title: {
        homePage: 'Bucket Manager',
        exemples: 'Examples',
        systemInfo: 'System info',
        userInfo: 'User info'
    },
    paragraph: {
        homeDescription: {
            part1: 'This is a basic plugin template.',
            part2: 'Edit <1>src/pages/Home.js</1> and save to reload.'
        }
    },
    link: {
        blipDataExample: 'Get blip data',
        swrExemple: 'SWR fetch data'
    },
    input: {
        saveAs: 'Save as'
    },
    messages: {
        invalidDoc: 'Invalid document: ',
        emptyDoc: 'The document is empty',
        sameName: 'If there is another document with the same name, its content will be overriden',
        cantChangeDoc: `You can't change this document through this plugin`,
        editing: 'Editing ',
        blipPortalPrefix: `The prefix 'blip_portal:' is reserved for internal bucket documents`
    },
    button: {
        repository: 'Visit the Documentation',
        createNewDocument: 'Create new document',
        saveDocument: 'Save document',
        cancel: 'Cancel',
        save: 'Save',
        editDocument: 'Edit document',
        deleteDocument: 'Delete document'
    },
    menu: {
        goBack: 'Back'
    },
    name: 'Name',
    shortName: 'Short name',
    email: 'E-mail',
    phone: 'Phone',
    identity: 'Identity',
    identifier: 'Identifier',
    language: 'en',
    accessKey: 'Access key',
    template: 'Template',
    creationDate: 'Creation date',
    documentEditor: 'Document Editor'
};

export default translations;
